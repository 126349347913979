import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import C3Chart from "../../extensions/ChartC3";

class CompanySalesByKind extends React.Component {
  renderProcessingChart = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Processando dados...</h2>
        <CircularProgress size={90} />
      </div>
    );
  };

  renderChart = () => {
    const format = this.props.chartGrouping === 'daily'
      ? '%d/%m'
      : '%m/%Y';
    return (
      <C3Chart
        axis={{
          x: {
            type: 'timeseries',
            tick: { format },
          },
        }}
        data={{
          types: {
            first_price: 'area-spline',
            normal: 'area-spline',
            exclusive: 'area-spline',
            vip: 'area-spline',
          },
          groups: [['first_price', 'normal', 'exclusive', 'vip']],
          names: {
            first_price: 'Primeiro Preço' ,
            normal: 'Normal' ,
            exclusive: 'Exclusivo',
            vip: 'Vip',
            no_ecash: 'Sem cashback',
          },
          xFormat: '%Y-%m-%d',
          keys: {
            x: 'date_reference',
            value: ['first_price', 'normal', 'exclusive', 'vip', 'no_ecash'],
          },
          json: this.props.chartData,
        }}
      />
    );
  };

  render() {
    return (
      <div>
        { this.props.loading ?
          this.renderProcessingChart() :
          this.renderChart()
        }
      </div>
    );
  }
}

export default CompanySalesByKind;
