import React from 'react';
import classNames from 'classnames';
import fetch from 'isomorphic-fetch';

export default class ProductPriceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: { ...props.product_price },
      isSubmitting: false,
      errors: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { form } = this.state;
    const { updateOriginalState, endpointURL } = this.props;

    this.setState({ isSubmitting: true, errors: [] });

    const authenticityToken = $('meta[name="csrf-token"]')[0].content;

    return fetch(endpointURL, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken,
      },
      body: JSON.stringify({ product_price: form }),
    }).then(response => response.json())
      .then((json) => {
        this.setState({ isSubmitting: false });

        if (json.errors) {
          this.setState({ errors: json.errors });
          return;
        }

        updateOriginalState(json.product_price);
      });
  }

  handleChange = (event) => {
    const { target: { value, name } } = event;

    this.setState({
      form: { ...this.state.form, [name]: value },
    });
  }

  render() {
    const { form: { price }, errors, isSubmitting } = this.state;
    const { currentPrice } = this.props;

    return (
      <div className="offer-form-overlay">
        <form onSubmit={this.handleSubmit} className="default-form">
          <div className="last-price">
            Preço anterior: {currentPrice}
          </div>

          <div className={classNames('input', 'string', { field_with_errors: errors.price })}>
            <label className="string">Preço</label>
            <input type="text" name="price" onChange={this.handleChange} value={price} />
            {errors.price ? <div className="offer-form-error-message">{errors.price}</div> : null}
          </div>

          <div className="form-actions">
            <button className="blue-btn" onClick={this.handleSubmit} type="submit" disabled={isSubmitting}>
              Salvar Preço
            </button>

            <button className="red-btn close-btn" type="button" onClick={this.props.closeModal}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    );
  }
}
