import React from 'react';
import classNames from 'classnames';
import 'moment/locale/pt-br';
import currency from 'currency.js';

export default class OfferMeuPostoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { ...props.offer },
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateOfferState(this.state.form);
  }

  handleMultipleCheckboxChange = (event) => {
    const { target } = event;
    const { name } = target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: this._processPaymentMethodIds(target),
      },
    });
  }

  handleChange = (event) => {
    const { target: { value, name } } = event;

    this.setState({
      form: { ...this.state.form, [name]: value },
    });
  }

  handleDateChange = (name) => {
    const { form } = this.state;

    return (date) => {
      this.setState({ form: { ...form, [name]: date.toISOString() } });
    };
  }

  sumMoney = (money1, money2) => {
    return currency(money1, { precision: 3, decimal: ',' }).add(money2);
  }

  translatedKind = () => {
    const { form: { kind } } = this.state;
    const translation = {
      'first_price': 'Primeiro Preço',
      'normal': 'Cliente Recorrente',
      'exclusive': 'Preço Exclusivo',
      'vip': 'Preço VIP',
      'no_ecash': '-',
      'custom_1': 'Especial 1',
      'custom_2': 'Especial 2',
      'custom_3': 'Especial 3',
      'custom_4': 'Especial 4',
      'custom_5': 'Especial 5',
      'custom_6': 'Especial 6'
    };
    return translation[kind];
  }

  render() {
    const { form: { debit_ecash, credit_ecash, product_name } } = this.state;
    const { errors, isSubmitting } = this.props;

    return (
      <div className="offer-form-overlay">
        <form onSubmit={this.handleSubmit} className="default-form">
          <div className={classNames('last-price')}>
            {product_name} - {this.translatedKind()}
          </div>

          <div className="input-offer-group">
            <div className={classNames('input', 'string', { field_with_errors: (errors.debit_ecash && errors.debit_ecash.length) })}>
              <label className="string">eCash à vista</label>
              <input type="text" name="debit_ecash" value={debit_ecash} onChange={this.handleChange} />
              {(errors.debit_ecash && errors.debit_ecash.length) ? <div className="offer-form-error-message">{errors.debit_ecash}</div> : null}
            </div>

            <div className={classNames('input', 'string', { field_with_errors: (errors.credit_ecash && errors.credit_ecash.length) })}>
              <label className="string">eCash Crédito</label>
              <input type="text" name="credit_ecash" value={credit_ecash} onChange={this.handleChange} />
              {(errors.credit_ecash && errors.credit_ecash.length) ? <div className="offer-form-error-message">{errors.credit_ecash}</div> : null}
            </div>
          </div>

          <div className="form-actions">
            <button className="blue-btn" onClick={this.handleSubmit} type="submit" disabled={isSubmitting}>
              Salvar Oferta
            </button>

            <button className="red-btn close-btn" type="button" onClick={this.props.closeModal}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    );
  }
}
