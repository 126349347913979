import React, { useCallback, useRef, useState, useEffect } from "react";
import ReactTags from "react-tag-autocomplete";

const TagInput = (props) => {
  const [tags, setTags] = useState(props.tags);

  const [suggestions, setSuggestions] = useState([])

  const reactTags = useRef();

  const onDelete = useCallback(
    (tagIndex) => {
      console.log('deleted', tags)
      setTags(tags.filter((_, i) => i !== tagIndex));
    },
    [tags]
  );

  const onAddition = useCallback(
    (newTag) => {
      console.log(tags);
      console.log("onAddition", tags);
      setTags([...tags, newTag]);
    },
    [tags]
  );

  const inputTags = () => {
    console.log(tags)

    return tags.map((tag) => tag.name);
  }

  return (
    <div className="input">
      <label for="product[tags]">Tags</label>
      <input
        value={tags.map((tag) => tag.name).join(",")}
        type="hidden"
        name="product[tag_list]"
      />
      <ReactTags
        ref={reactTags}
        tags={tags}
        allowNew
        newTagText="Nova tag: "
        suggestions={suggestions}
        onDelete={onDelete}
        onAddition={onAddition}
      />
    </div>
  );
};

export default TagInput;
