import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import QRCodeReader from '../reservation/QRCodeReader';

const styles = theme => ({
  qrCode: {
    marginTop: '35px',
  },
  title: {
    textAlign: 'center',
  }
});

class LinkQrCodeActivation extends React.Component {

  handleProcessCheckIn = ({ token }) => {
    window.location = `${this.props.link_creation_path}?create_other=true&token=${token}&${window.location.search.substr(1)}`;
    console.log('parsed successfully', token);
  };

  render() {
    return (
      <Grid container justify="center" spacing={8}>
        <Grid item>
          <h4 className={this.props.classes.title}>LER NOVO QR CODE</h4>
          <QRCodeReader
            className={this.props.classes.qrCode}
            scanningOnStart={true}
            onTokenValidation={this.handleProcessCheckIn} />
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles)(LinkQrCodeActivation);
