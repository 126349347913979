import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import c3 from 'c3';

class ChartC3 extends React.Component {
  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    const chart = c3.generate({
      bindto: findDOMNode(this),
      data: this.props.data,
      axis: this.props.axis,
      tooltip: this.props.tooltip,
      legend: this.props.legend,
    });
  }

  render() {
    return <div id="chart"/>;
  }
};

ChartC3.propTypes = {
  data: PropTypes.object.isRequired,
  axis: PropTypes.object,
  tooltip: PropTypes.object,
  legend: PropTypes.object,
};

ChartC3.defaultProps = {
  axis: {},
  tooltip: {},
  legend: {},
};

export default ChartC3;
