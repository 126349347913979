import React from 'react';
import classNames from 'classnames';
import OfferEGasosaForm from './OfferEGasosaForm';
import OfferMeuPostoForm from './OfferMeuPostoForm';
import currency from 'currency.js';
import axios from 'axios';

export default class OfferFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offer: { ...this.props.offer },
      automatic_offer: { ...this.props.automatic_offer },
      open: this.props.open || false,
      isSubmittingExpire: false,
      editEnabled: true,
      isSubmitting: false,
      errors: [],
    };
  }

  isEGasosa = () => {
    return this.props.appId === 'egasosa';
  }

  toggleFormModal = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  renderForm = () => {
    const { open, offer, automatic_offer } = this.state;

    if (!open) return;
    // const currentPrice = (offer.price !== '0,000')
    //   ? currency(offer.price, { precision: 3, decimal: ',' }).format()
    //   : null;

    const OfferForm = this.isEGasosa() ? OfferEGasosaForm : OfferMeuPostoForm;

    return (
      <OfferForm
        updateOfferState={this.updateOffer}
        closeModal={this.toggleFormModal}
        offer={offer}
        automaticOffer={automatic_offer}
        isSubmitting={this.state.isSubmitting}
        errors={this.state.errors}
      />
    );
  }

  removeOfferWithConfirm = () => {
    // TODO need to be implemented
    const { product_name } = this.state;
    if (window.confirm(`Tem certeza que deseja expirar esta oferta de ${product_name}?`)) {
      this.removeOffer();
    }
  }

  updateOffer = (form) => {
    this.setState({ isSubmitting: true, errors: [] });
    const request = this.isEGasosa()
      ? { state: 'automatic_offer', body: 'automatic_offer_price', url: this.props.automaticOfferURL }
      : { state: 'offer', body: 'offer', url: this.props.offerURL };

    axios.post(request.url, { [request.body]: form })
      .then(({ data: json }) =>
        this.setState({ isSubmitting: false, [request.state]: json[request.body], open: false })
      ).catch((err) => {
        if (err.response.status === 422 && err.response.data.errors) {
          this.setState({ errors: err.response.data.errors });
        }
        this.setState({ isSubmitting: false });
        // eslint-disable-next-line no-console
        console.log('error during offer update', err);
      });
  }

  removeOffer = () => {
    const request = this.isEGasosa()
      ? {
        state: { automatic_offer: { ...this.state.offer, value_debit: '0,000', value_debit: '0,000' } },
        url: this.props.automatic_offer.expire_url,
      }
      : {
        state: { offer: { ...this.state.offer, price: '0,000', id: null } },
        url: this.props.expire_url,
      };
    this.setState({ isSubmittingExpire: true }, () => {
      return axios.delete(request.url)
        .then(() => this.setState({ ...request.state, isSubmittingExpire: false }))
        .catch((err) => {
          this.setState({ isSubmittingExpire: false });
          // eslint-disable-next-line no-console
          console.log('error during offer exclusion', err);
        });
    });
  }

  hasOffer = () => {
    const { offer: { id } } = this.state;
    return id !== null;
  }

  formButtonLabel = () => {
    const {
      offer: { debit_ecash, credit_ecash },
      automatic_offer: { value_debit, value_credit },
    } = this.state;

    if (this.isEGasosa() || this.hasOffer()) {
      var price_value = this.isEGasosa()
        ? { debit: value_debit, credit: value_credit }
        : { debit: debit_ecash, credit: credit_ecash };

      var prices = [currency(price_value.debit, { precision: 3, decimal: ',' }).format()];

      if ( price_value.credit ) {
        prices.push(currency(price_value.credit, { precision: 3, decimal: ',' }).format());
      }

      return prices.join(' / ');
    } else {
      return '+';
    }
  }

  render() {
    const { isSubmittingExpire } = this.state;

    return (
      <div className="offer-form-container">
        <button onClick={this.toggleFormModal} type="button"
          className={classNames('offer-form-value', { hidden: !this.state.editEnabled })}>
          {this.formButtonLabel()}
        </button>
        <button onClick={this.removeOfferWithConfirm} disabled={isSubmittingExpire} type="button" className={classNames({ hidden: (!this.state.editEnabled || !(this.isEGasosa() || this.hasOffer())) })}>X</button>
        {this.renderForm()}
      </div>
    );
  }
}
