/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//
import WebpackerReact from 'webpacker-react';
import OfferFormContainer from './components/OfferFormContainer';
import ProductPriceFormContainer from './components/ProductPriceFormContainer';
import LoginContainer from './components/LoginContainer';
import ReservationFormContainer from './components/ReservationFormContainer';
import CompanyDashboardContainer from './components/company/CompanyDashboardContainer';
import GroupDashboardContainer from './components/company/GroupDashboardContainer';
import CompanyLinksDashboardContainer from './components/company/CompanyLinksDashboardContainer';
import LinkQrCodeActivation from './components/link/LinkQrCodeActivation';
import TagInput from './components/form/TagInput';

import _numeral from "./components/extensions/numeral";
import _axios from './components/extensions/axios';

WebpackerReact.setup({
  OfferFormContainer,
  ProductPriceFormContainer,
  ReservationFormContainer,
  CompanyDashboardContainer,
  GroupDashboardContainer,
  CompanyLinksDashboardContainer,
  LinkQrCodeActivation,
  LoginContainer,
  TagInput,
});
