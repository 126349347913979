import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import C3Chart from "../../extensions/ChartC3";

class MonthlyInstallationsByLink extends React.Component {
  renderProcessingChart = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Processando dados...</h2>
        <CircularProgress size={90} />
      </div>
    );
  };

  renderChart = () => {
    const [ first ] = this.props.chartData;
    const value = first ? Object.keys(first).filter(key => key !== 'date_reference') : [];
    return (
      <C3Chart
        axis={{
          x: {
            type: 'timeseries',
            tick: { format: '%d/%m' }
          }
        }}
        legend={{
          show: false
        }}
        data={{
          type: 'pie',
          xFormat: '%Y-%m-%d',
          keys: {
            x: 'date_reference',
            value: value,
          },
          json: this.props.chartData,
        }}
        tooltip= {{
          format: {
            value: function (value, ratio, id, index) { return value; }
          }
        }}
      />
    );
  };

  render() {
    return (
      <div>
        { this.props.loading ?
            this.renderProcessingChart() :
            this.renderChart()
        }
      </div>
    );
  }
}

export default MonthlyInstallationsByLink;
