import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import C3Chart from "../../extensions/ChartC3";

class DailyInstallationsByLink extends React.Component {
  renderProcessingChart = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Processando dados...</h2>
        <CircularProgress size={90} />
      </div>
    );
  };

  prepareDataChart = () => {
    const values = _(this.props.chartData || [])
      .map(item => Object.keys(item))
      .flatten()
      .uniq()
      .reject(item => item === 'date_reference')
      .value();

    const data = this.props.chartData
      .map((_data) => {
        const Total = _.chain(_data)
          .pick(values)
          .values()
          .sum()
          .value();

        return Object.assign({ Total }, _data);
      })
      .map((_data) => _.mapValues(_data, (value) => value === 0 ? null : value));

    return [ values, data ];
  }

  renderChart = () => {
    const [ values, data ] = this.prepareDataChart();
    const format = this.props.chartGrouping === 'daily'
      ? '%d/%m'
      : '%m/%Y';

    return (
      <C3Chart
        axis={{
          x: {
            type: 'timeseries',
            tick: { format },
          },
        }}
        data={{
          groups: [values],
          type: 'bar',
          types: {
            'Total': 'spline',
          },
          xFormat: '%Y-%m-%d',
          keys: {
            x: 'date_reference',
            value: [...values, 'Total'],
          },
          json: data,
        }}
        legend= {{
          hide: true
        }}
        tooltip= {{
          format: {
            value: (value) => value,
          },
        }}
      />
    );
  };

  render() {
    return (
      <div>
        { this.props.loading ?  this.renderProcessingChart() : this.renderChart() }
      </div>
    );
  }
}

export default DailyInstallationsByLink;
