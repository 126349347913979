import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import C3Chart from "../../extensions/ChartC3";

class CompanySalesByPaymentMethod extends React.Component {
  renderProcessingChart = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Processando dados...</h2>
        <CircularProgress size={90} />
      </div>
    );
  };

  renderChart = () => {
    const format = this.props.chartGrouping === 'daily'
      ? '%d/%m'
      : '%m/%Y';
    return (
      <C3Chart
        axis={{
          x: {
            type: 'timeseries',
            tick: { format}
          }
        }}
        data={{
          types: {
            debit: 'area-spline',
            credit: 'area-spline'
          },
          groups: [['debit', 'credit']],
          names: {
            debit: 'Debito' ,
            credit: 'Crédito'
          },
          xFormat: '%Y-%m-%d',
          keys: {
            x: 'date_reference',
            value: ['debit', 'credit']
          },
          json: this.props.chartData,
        }}
      />
    );
  };

  render() {
    return (
      <div>
        { this.props.loading ?
            this.renderProcessingChart() :
            this.renderChart()
        }
      </div>
    );
  }
}

export default CompanySalesByPaymentMethod;
