import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import C3Chart from "../../extensions/ChartC3";

class CompanySalesByProduct extends React.Component {
  renderProcessingChart = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Processando dados...</h2>
        <CircularProgress size={90} />
      </div>
    );
  };

  prepareDataChart = () => {
    // const dataDefault = value.reduce((acc, key) => { acc[key] = 0; return acc }, {});
    // const data = this.props.chartData.map(data => Object.assign({}, dataDefault, data));

    return { value, data: this.props.chartData };
  }

  renderChart = () => {
    const value = new Set();
    this.props.chartData.forEach(item => Object.keys(item).forEach(key => value.add(key)));
    value.delete('date_reference');

    return (
      <C3Chart
        axis={{
          x: {
            type: 'timeseries',
            tick: { format: '%d/%m' }
          }
        }}
        legend={{
          hide: true
        }}
        data={{
          type: 'pie',
          xFormat: '%Y-%m-%d',
          keys: {
            x: 'date_reference',
            value: Array.from(value),
          },
          json: this.props.chartData,
        }}
      />
    );
  };

  render() {
    return (
      <div>
        { this.props.loading ?
            this.renderProcessingChart() :
            this.renderChart()
        }
      </div>
    );
  }
}

export default CompanySalesByProduct;
