import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import CompanySalesByPaymentMethod from './charts/CompanySalesByPaymentMethod';
import CompanySalesByProduct from './charts/CompanySalesByProduct';
import CompanySalesByKind from './charts/CompanySalesByKind';
import CompanyUserRegisters from './charts/CompanyUserRegisters';
import DailyInstallationsByLink from './charts/DailyInstallationsByLink';
import DashBoardFilterForms from './forms/DashBoardFilterForms';
import { Subject, from, empty } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

class CompanyDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      app: this.props.app,
      error: '',
      filters: {
        chartGrouping: 'daily',
        period: 'thirty-days',
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      chartData: {
        sales_by_kind: [],
        users_registers: [],
        sales_by_payment_method: [],
        sales_by_product: [],
        installation_by_link: [],
      },
    };
  }

  componentDidMount(){
    this.loadChartData$ = new Subject();
    this.loadChartDataSubject$ = this.loadChartData$.pipe(
      switchMap(({ chartGrouping,  params }) => (
        from(axios.get(`/gestor/chart/${chartGrouping}`, { params }))
          .pipe(
            catchError((err) => {
              // eslint-disable-next-line no-console
              console.log('err', err);
              this.setState({ error: err.message, loading: false });
              return empty();
            })
          )
      ))
    ).subscribe(this.setupChartData.bind(this));
    this.loadChartData();
  }

  componentWillUnmount() {
    this.loadChartDataSubject$.unsubscribe();
  }

  getPeriodRange(period, startDate = this.state.filters.startDate, endDate = this.state.filters.endDate) {
    const periodRange = {
      'thirty-days': {
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      'fifteen-days': {
        startDate: moment().subtract(15, 'days'),
        endDate: moment(),
      },
      'seven-days': {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      'custom': {
        startDate,
        endDate,
      },
      'six-months': {
        startDate: moment().subtract(5, 'months').startOf('month'),
        endDate: moment().endOf('month'),
      },
      'twelve-months': {
        startDate: moment().subtract(11, 'months').startOf('month'),
        endDate: moment().endOf('month'),
      },
    };

    return periodRange[period];
  }

  onChangePeriod = (period, startDate, endDate) => {
    const periodRange = this.getPeriodRange(period, startDate, endDate);
    this.setState({ filters: { ...this.state.filters, ...periodRange, period } }, () => {
      const notEqual = _.negate(_.isEqual);
      const rangeUpdated = notEqual(
        { s: startDate.format('DD/MM/YYYY'), e: endDate.format('DD/MM/YYYY') },
        { s: periodRange.startDate.format('DD/MM/YYYY'), e: periodRange.endDate.format('DD/MM/YYYY') }
      );

      if(rangeUpdated) {
        this.loadChartData();
      }
    });
  };

  onChangeDateRange = (startDate, endDate) => {
    this.setState({ filters: { ...this.state.filters, startDate, endDate } }, () => {
      if (endDate.diff(startDate, 'months') > 3) {
        this.setState({ error: 'Range não pode ser maior que três meses' });
      } else {
        this.loadChartData();
      }
    });
  };

  onChangeChartGrouping = (chartGrouping) => {
    const period = {
      'daily': 'thirty-days',
      'monthly': 'six-months',
    }[chartGrouping];
    const periodRange = this.getPeriodRange(period);

    this.setState({ filters: { ...this.state.filters, chartGrouping, period, ...periodRange }}, () => {
      this.loadChartData();
    });
  }

  loadChartData() {
    const { filters, app } = this.state;
    const { startDate, endDate, chartGrouping } = filters;
    const params = {
      app_id: app,
      start_date: startDate.format('DD-MM-YYYY'),
      end_date: endDate.format('DD-MM-YYYY'),
    };

    this.setState({ loading: true }, () => {
      this.loadChartData$.next({ params, chartGrouping });
    });
  }

  setupChartData({ data=[] }) {
    const chartBlankSlat =  _.reduce(this.state.chartData, (chart, _value, key) => ({ ...chart, [key]: []}), {});
    const chartData = data.reduce((charts, _data) => {
      // eslint-disable-next-line camelcase
      const date_reference = _data.date_reference;
      Object.keys(this.state.chartData).forEach((chart) => {
        charts[chart].push({ date_reference, ..._data[chart] });
      });
      return charts;
    }, chartBlankSlat);
    this.setState({ loading: false, chartData, error: '' });
  }

  render() {
    const { filters } = this.state;
    return (
      <div>
        <DashBoardFilterForms
          error={this.state.error}
          period={filters.period}
          chartGrouping={filters.chartGrouping}
          startDate={filters.startDate}
          endDate={filters.endDate}
          onChangePeriod={this.onChangePeriod}
          onChangeDateRange={this.onChangeDateRange}
          onChangeChartGrouping={this.onChangeChartGrouping}
        />
        <div className="dashboards-charts ">
          <div className="dashboards-chart-box">
            <h2>Vendas Por Tipo de Cliente</h2>
            <CompanySalesByKind
              chartGrouping={filters.chartGrouping}
              loading={this.state.loading}
              chartData={this.state.chartData.sales_by_kind} />
          </div>
          <div className="dashboards-chart-box">
            <h2>Vendas por Métodos de pagamento</h2>
            <CompanySalesByPaymentMethod
              chartGrouping={filters.chartGrouping}
              loading={this.state.loading}
              chartData={this.state.chartData.sales_by_payment_method} />
          </div>
          <div className="dashboards-chart-box">
            <h2>Vendas por Produto</h2>
            <CompanySalesByProduct
              loading={this.state.loading}
              chartData={this.state.chartData.sales_by_product} />
          </div>
          <div className="dashboards-chart-box">
            <h2>Total cadastro Usuários</h2>
            <CompanyUserRegisters
              chartGrouping={filters.chartGrouping}
              loading={this.state.loading}
              chartData={this.state.chartData.users_registers} />
          </div>
          {
            this.props.app !== 'meu_posto'
              ? null
              : <div className="dashboards-chart-box">
                <h2>Links Ativados</h2>
                <DailyInstallationsByLink
                  chartGrouping={filters.chartGrouping}
                  loading={this.state.loading}
                  chartData={this.state.chartData.installation_by_link} />
              </div>
          }
        </div>
      </div>
    );
  }
}

export default CompanyDashboardContainer;
