import React from 'react';
import classNames from 'classnames';
import ProductPriceForm from './ProductPriceForm';

export default class ProductPriceFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product_price: { ...this.props.product_price },
      open: this.props.open || false,
    };
  }

  toggleFormModal = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  updateOriginalState = (product_price) => {
    this.setState({ product_price, open: false });
  }

  renderForm = () => {
    const { open, product_price } = this.state;
    const { endpointURL } = this.props;

    if (!open) return;

    return (
      <ProductPriceForm
        updateOriginalState={this.updateOriginalState}
        closeModal={this.toggleFormModal}
        product_price={product_price}
        currentPrice={product_price.price}
        endpointURL={endpointURL}
      />
    );
  }

  render() {
    const { product_price: { price } } = this.state;

    return (
      <div className="offer-form-container">
        <button onClick={this.toggleFormModal} type="button" className="offer-form-value">{price}</button>
        {this.renderForm()}
      </div>
    );
  }
}


