import React from 'react';
import TextMask from './form/text_mask';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import Lock from '@material-ui/icons/Lock';
import _ from 'lodash';
import axios from 'axios';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#6bffff',
      main: '#00ccff',
      dark: '#009bcc',
      contrastText: '#fff',
    },
  },
});

const PAGES = {
  PHONE_NUMBER: 'number_input',
  CODE_NUMBER: 'code_input',
};

export default class LoginContainer extends React.Component {
  state = {
    phone: '',
    verificationCode: '',
    phoneStriped: '',
    processsing: false,
    page:PAGES.PHONE_NUMBER,
    error:'',
  }

  generateToken = (e) => {
    e.preventDefault();
    if (!this.state.processsing) {
      this.setState({ processsing: true, error: '' },() => {
        return axios.post(this.props.token_generator_path,{ phone: this.state.phone })
          .then(() => {
            this.setState({ processsing: false, page: PAGES.CODE_NUMBER });
          })
          .catch((err) => {
            let error = 'Ocorreu um erro na geração do codigo, por favor tente novamente ou contate o suporte';
            if (err.response.data.error) {
              error= err.response.data.error;
            }
            // eslint-disable-next-line no-console
            console.log('error during token validation', err);
            this.setState({ processsing: false, error });
          });
      });
    }
  }

  validateCode = (e) => {
    e.preventDefault();
    if (!this.state.processsing) {
      this.setState({ processsing: true }, () => {
        return axios.post(this.props.token_validator_path,{
          password: this.state.verificationCode,
          phone: this.state.phoneStriped,
        })
          .then((res) => {
            this.setState({ processsing: false });
            window.location = res.data.navigate;
          })
          .catch((err) => {
            let error = '';
            if (err.response.data.error) {
              error= err.response.data.error;
            }
            // eslint-disable-next-line no-console
            console.log('error during token validation', err);
            this.setState({ processsing: false, error });
          });
      });
    }
  }

  onChange = (inputForm) => (el) =>
    this.setState({
      [inputForm]: el.target.value,
      [`${inputForm}Striped`]: el.target.value.replace(/[^\d]/g, ''),
    })

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  phoneInputForm = () => {
    return <div>
      <div className="mdc-card" style={{padding: '8px 0 24px', width: '368px'}}>
        { this.state.error && <h2 style={{color: 'red'}}>{this.state.error}</h2> }
        <section className="mdc-card__primary">
          <Grid container spacing={0} alignItems="flex-end">
            <Grid xs={2} item>
              <PhoneIphone style={{ margin: '0 20px' }}/>
            </Grid>
            <Grid item xs={10}>
              <TextField
                style={{width: '90%'}}
                label="CELULAR"
                value={this.state.phone}
                onChange={this.onChange('phone')}
                placeholder="(**) *****-****"
                InputProps={{
                  inputProps: { mask: ['(',/\d/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
                  inputComponent: TextMask,
                  autoComplete:'off',
                  autoCorrect:'off',
                  autoCapitalize:'off',
                }}
              />
            </Grid>
          </Grid>
        </section>
      </div>
      <button
        id="sign-in-button"
        style={{ 'display': this.state.page === PAGES.PHONE_NUMBER ? 'block': 'none' }}
        disabled={this.state.phoneStriped.length !== 11 || this.state.processsing}
        onClick={this.generateToken}
        className="mdc-button mdc-button--raised mdc-button--primary company-login-form__submit-button">
        Gerar Token
      </button>
    </div>;
  }

  codeInputForm = () => {
    return <div>
      <div className="mdc-card" style={{padding: '8px 0 24px', width: '368px'}}>
        { this.state.error && <h2 style={{color: 'red'}}>{this.state.error}</h2> }
        <section className="mdc-card__primary">
          <Grid container spacing={0} alignItems="flex-end">
            <Grid xs={2} item>
              <Lock style={{ margin: '0 20px' }}/>
            </Grid>
            <Grid item xs={10}>
              <TextField
                style={{width: '90%'}}
                label="CÓDIGO"
                value={this.state.verificationCode}
                onChange={this.onChange('verificationCode')}
                placeholder="******"
                InputProps={{
                  inputProps: { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/] },
                  inputComponent: TextMask,
                  autoComplete:'off',
                  autoCorrect:'off',
                  autoCapitalize:'off',
                }}
              />
            </Grid>
          </Grid>
        </section>
      </div>
      <button
        id="validate-code-in-button"
        style={{ 'display': this.state.page === PAGES.PHONE_NUMBER ? 'none': 'block' }}
        disabled={this.state.verificationCode.trim().length !== 6 || this.state.processsing}
        onClick={this.validateCode}
        className="mdc-button mdc-button--raised mdc-button--primary company-login-form__submit-button">
        Validar token
      </button>
    </div>;
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div id="phoneInputForm" className="token-delivery">
          { this.state.page === PAGES.PHONE_NUMBER
            ? this.phoneInputForm()
            : this.codeInputForm()
          }
        </div>
      </MuiThemeProvider>
    );
  }
}
