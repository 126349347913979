import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DatePicker from 'react-datetime';

import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  root: {
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '30px',
    backgroundColor: '#fff',
  },
  toggleGroup: {
    boxShadow: 'none',
  },
  toggleButton: {
    color: '#003344',
    border: 'solid 1px rgb(47, 197, 244)',
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: 'rgba(0, 0, 0, 0)',
    },
    '&:before': {
      borderBottomColor: 'rgba(0, 0, 0, 0)',
    },
  },
  formControl: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  select: {
    display: 'block',
    borderRadius: '2px',
    border: '1px solid #2fc5f4',
    background: '#fff',
    padding: '0 13px',
    height: '31px',
    outline: 'none',
  },
  icon: {
    color: '#006795',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.75em',
    marginRight: '8px',
  },
  datePicker: {
    width: '105px',
  },
  datePickerError: {
    borderColor: 'red !important',
  },
  errorText: {
    color: 'red',
    marginLeft: '15px',
  }
});


class DashBoardFilterForms extends React.Component {
  onChangePeriod = (event) => {
    this.props.onChangePeriod(event.target.value, this.props.startDate, this.props.endDate);
  }

  onChangeChartGrouping = (event, value) => {
    if(value) {
      this.props.onChangeChartGrouping(value);
    }
  }

  onChangeDate = (on) => {
    return (value) => {
      const startDate = on === 'start' ? value : this.props.startDate;
      const endDate = on === 'end' ? value : this.props.endDate;
      this.props.onChangeDateRange(startDate, endDate);
    };
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={8}>
          <Grid item className={ this.props.classes.formControl }>
            <label className={this.props.classes.label}>Agrupar por</label>
            <ToggleButtonGroup
              value={this.props.chartGrouping}
              className={this.props.classes.toggleGroup}
              onChange={this.onChangeChartGrouping} exclusive>
              <ToggleButton className={this.props.classes.toggleButton} value="daily">
                <span> Dia </span>
              </ToggleButton>
              <ToggleButton className={this.props.classes.toggleButton} value="monthly">
                <span> Mês </span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item className={ this.props.classes.formControl }>
            <label className={this.props.classes.label}>Intervalo</label>
            <select
              className={this.props.classes.select}
              onChange={this.onChangePeriod}
              value={this.props.period}
            >
              {
                this.props.chartGrouping === 'daily'
                  ? [
                    <option value="seven-days">7 Dias</option>,
                    <option value="fifteen-days">15 Dias</option>,
                    <option value="thirty-days">30 Dias</option>,
                    <option value="custom">Personalizado</option>
                  ]
                  : null
              }
              {
                this.props.chartGrouping === 'monthly'
                  ? [
                    <option value="six-months">6 Meses</option>,
                    <option value="twelve-months">12 Meses</option>
                  ]
                  : null
              }
            </select>
          </Grid>
          <Grid item className={ this.props.classes.formControl }>
            <label className={this.props.classes.label}>Inicio</label>

            <DatePicker
              inputProps={{
                className: classNames({
                  [this.props.classes.datePicker]: true,
                  [this.props.classes.datePickerError]: !!this.props.error,
                }),
                disabled: this.props.period !== 'custom'
              }}
              closeOnSelect
              timeFormat={false}
              isValidDate={(current) => current.isBefore(this.props.endDate) }
              dateFormat="DD/MM/YYYY"
              onChange={this.onChangeDate('start')}
              value={ this.props.startDate }
            />
          </Grid>
          <Grid item className={ this.props.classes.formControl }>
            <label className={this.props.classes.label}>Fim</label>
            <DatePicker
              inputProps={{
                className: classNames({
                  [this.props.classes.datePicker]: true,
                  [this.props.classes.datePickerError]: !!this.props.error,
                }),
                disabled: this.props.period !== 'custom'
              }}
              closeOnSelect
              isValidDate={(current) => current.isAfter(this.props.startDate) && current.isBefore(moment()) }
              timeFormat={false}
              dateFormat="DD/MM/YYYY"
              onChange={this.onChangeDate('end')}
              value={ this.props.endDate }
            />
          </Grid>
        </Grid>
        {
          !!this.props.error
            ? <FormHelperText className={this.props.classes.errorText}>{this.props.error}</FormHelperText>
            : null
        }
      </div>
    );
  }
}
export default withStyles(styles)(DashBoardFilterForms);
