import React from 'react';
import axios from 'axios';
import moment from 'moment';
import classNames from 'classnames';
import CompanyInstallationByLink from './charts/MonthlyInstallationsByLink';

class CompanyLinksDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      previousMonthLoading: false,
      app: this.props.app,
      filter: {
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().startOf('month'),
      },
      chartData: {
        currentMonth: { installation_by_link: [] },
        previousMonth: { installation_by_link: [] },
      }
    };
  }

  componentDidMount(){
    this.setState({ loading: true }, () => {
      const { startDate, endDate } = this.state.filter;
      const params = {
        start_date: startDate.format('DD/MM/YYYY'),
        end_date: endDate.format('DD/MM/YYYY'),
        app_id: this.state.app,
      };
      axios.get('/gestor/chart/monthly', { params })
        .then((res) => {
          this.setupChartData(res.data);
        }).catch((err) => {
          this.setState({ error: err, loading: false });
        });
    });
  }

  setupChartData(data=[]) {
    const { startDate, endDate } = this.state.filter;
    const currentMonthData = _.find(data, ['date_reference', endDate.format('YYYY-MM-DD')]);
    const previousMonthData = _.find(data, ['date_reference', startDate.format('YYYY-MM-DD')]);

    const currentMonth = {
      installation_by_link: [{
        date_reference: endDate,
        ..._.get(currentMonthData, 'installation_by_link')
      }]
    };

    const previousMonth = {
      installation_by_link: [{
        date_reference: startDate,
        ..._.get(previousMonthData, 'installation_by_link')
      }]
    };

    this.setState({ loading: false, chartData: {currentMonth, previousMonth }});
  }

  render() {
    const chartData = this.state.chartData;
    return (
      <div className="dashboards-charts ">
        <div className="dashboards-chart-box">
          <h2>Mês anterior</h2>
          <CompanyInstallationByLink loading={this.state.loading} chartData={chartData.previousMonth.installation_by_link} />
        </div>

        <div className="dashboards-chart-box">
          <h2>Mês atual</h2>
          <CompanyInstallationByLink loading={this.state.loading} chartData={chartData.currentMonth.installation_by_link} />
        </div>
      </div>
    )
  }
}

export default CompanyLinksDashboardContainer;
