import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import GroupUserRegisters from './charts/GroupUserRegisters';
import GroupSalesByCompany from './charts/GroupSalesByCompany';
import GroupCustomersByCompany from './charts/GroupCustomersByCompany';
import DashBoardFilterForms from './forms/DashBoardFilterForms';
import { Subject, from, empty } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

class GroupDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      app: this.props.app,
      group_id: this.props.group_id,
      companies: [],
      error: '',
      filters: {
        chartGrouping: 'daily',
        period: 'thirty-days',
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      mapper: {
        users_registers: 'users_registers',
        total_customers: 'total_customers',
        total_sales: 'sales_by_payment_method',
      },
      chartData: {
        users_registers: [],
        total_sales: [],
        total_customers: [],
      },
    };
  }

  componentDidMount(){
    this.loadChartData$ = new Subject();
    this.loadChartDataSubject$ = this.loadChartData$.pipe(
      switchMap(({ chartGrouping,  params }) => (
        from(axios.get(`/group/${this.props.group_id}/chart/${chartGrouping}`, { params }))
          .pipe(
            catchError((err) => {
              // eslint-disable-next-line no-console
              console.log('err', err);
              this.setState({ error: err.message, loading: false });
              return empty();
            })
          )
      ))
    ).subscribe(this.setupChartData.bind(this));
    this.loadChartData();
  }

  componentWillUnmount() {
    this.loadChartDataSubject$.unsubscribe();
  }

  getPeriodRange(period, startDate = this.state.filters.startDate, endDate = this.state.filters.endDate) {
    const periodRange = {
      'thirty-days': {
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      'fifteen-days': {
        startDate: moment().subtract(15, 'days'),
        endDate: moment(),
      },
      'seven-days': {
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      'custom': {
        startDate,
        endDate,
      },
      'six-months': {
        startDate: moment().subtract(5, 'months').startOf('month'),
        endDate: moment().endOf('month'),
      },
      'twelve-months': {
        startDate: moment().subtract(11, 'months').startOf('month'),
        endDate: moment().endOf('month'),
      },
    };

    return periodRange[period];
  }

  onChangePeriod = (period, startDate, endDate) => {
    const periodRange = this.getPeriodRange(period, startDate, endDate);
    this.setState({ filters: { ...this.state.filters, ...periodRange, period } }, () => {
      const notEqual = _.negate(_.isEqual);
      const rangeUpdated = notEqual(
        { s: startDate.format('DD/MM/YYYY'), e: endDate.format('DD/MM/YYYY') },
        { s: periodRange.startDate.format('DD/MM/YYYY'), e: periodRange.endDate.format('DD/MM/YYYY') }
      );

      if(rangeUpdated) {
        this.loadChartData();
      }
    });
  };

  onChangeDateRange = (startDate, endDate) => {
    this.setState({ filters: { ...this.state.filters, startDate, endDate } }, () => {
      if (endDate.diff(startDate, 'months') > 3) {
        this.setState({ error: 'Range não pode ser maior que três meses' });
      } else {
        this.loadChartData();
      }
    });
  };

  onChangeChartGrouping = (chartGrouping) => {
    const period = {
      'daily': 'thirty-days',
      'monthly': 'six-months',
    }[chartGrouping];
    const periodRange = this.getPeriodRange(period);

    this.setState({ filters: { ...this.state.filters, chartGrouping, period, ...periodRange }}, () => {
      this.loadChartData();
    });
  }

  loadChartData() {
    const { filters, app } = this.state;
    const { startDate, endDate, chartGrouping } = filters;
    const params = {
      app_id: app,
      start_date: startDate.format('DD-MM-YYYY'),
      end_date: endDate.format('DD-MM-YYYY'),
    };

    this.setState({ loading: true }, () => {
      this.loadChartData$.next({ params, chartGrouping });
    });
  }

  setupChartData({ data=[] }) {
    const companies = new Set();
    const chartBlankSlate =  _.reduce(this.state.chartData, (chart, _value, key) => ({ ...chart, [key]: []}), {});
    const groupedData = _.groupBy(data, 'date_reference');
    const chartData = _.reduce(groupedData, (charts, _companyData, date_reference) => {
      // eslint-disable-next-line camelcase
      _.forEach(this.state.mapper, (chart, key) => {
        const companyChart = _companyData.reduce((chartObject, _data) => {
          const total = Object.keys(_data[chart]).reduce((sum, field) => (sum += _data[chart][field]), 0);
          companies.add(_data.company);
          chartObject[_data.company] =  total;
          return chartObject;
        }, {});
        charts[key].push({ date_reference, ...companyChart });
      });
      return charts;
    }, chartBlankSlate);

    this.setState({ loading: false, chartData, error: '', companies: Array.from(companies) });
  }

  render() {
    const { filters } = this.state;
    return (
      <div>
        <DashBoardFilterForms
          error={this.state.error}
          period={filters.period}
          chartGrouping={filters.chartGrouping}
          startDate={filters.startDate}
          endDate={filters.endDate}
          onChangePeriod={this.onChangePeriod}
          onChangeDateRange={this.onChangeDateRange}
          onChangeChartGrouping={this.onChangeChartGrouping}
        />
        <div className="dashboards-charts ">
          <div className="dashboards-chart-box">
            <h2>Novos cadastros por postos</h2>
            <GroupUserRegisters
              companies={this.state.companies}
              chartGrouping={filters.chartGrouping}
              loading={this.state.loading}
              chartData={this.state.chartData.users_registers} />
          </div>
          <div className="dashboards-chart-box">
            <h2>Total vendas por Posto</h2>
            <GroupSalesByCompany
              companies={this.state.companies}
              chartGrouping={filters.chartGrouping}
              loading={this.state.loading}
              chartData={this.state.chartData.total_sales} />
          </div>
          <div className="dashboards-chart-box">
            <h2>Total clientes por Posto</h2>
            <GroupCustomersByCompany
              companies={this.state.companies}
              loading={this.state.loading}
              chartData={this.state.chartData.total_customers} />
          </div>
        </div>
      </div>
    );
  }
}

export default GroupDashboardContainer;
